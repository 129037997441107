<template>
    <div v-if="dialogVisible">
        <el-dialog title="场景预览" :visible.sync="dialogVisible" width="785px" top="1vh" :close-on-click-modal="false">
            <div class="card-main">
                <div class="step">
                    <div class="step-item" :class="{active:step==1}">
                        <div class="number">1</div>
                        <div class="text">
                            <div class="tit">基本信息</div>
                            <div class="desc">设置名片头像、姓名</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="step-item" :class="{active:step==2}">
                        <div class="number">2</div>
                        <div class="text">
                            <div class="tit">语音介绍</div>
                            <div class="desc">设置数字名片介绍内容</div>
                        </div>
                    </div>
                </div>

                <div class="from">
                    <!-- 第一步 -->
                    <div class="from-l" v-if="step==1">
                        <div class="title mt20">
                            个人信息
                        </div>
                        <div class="list">
                            <div class="list-tit">
                                <span>*</span>头像
                            </div>
                            <div class="list-content">
                                <upload-avatar @uploadSuccess="uploadSuccessAvatar" :url="avatar"></upload-avatar>
                            </div>
                        </div>
                        <div class="list">
                            <div class="list-tit">
                                <span>*</span>性别
                            </div>
                            <div class="list-content" style="line-height: 32px;">
                                <el-radio v-model="gender" :label="1">男</el-radio>
                                <el-radio v-model="gender" :label="2">女</el-radio>
                            </div>
                        </div>

                        <!-- <div class="list">
                            <div class="list-tit">
                                <span>*</span>风格
                            </div>
                            <div class="list-content">
                                <el-select size="small" style="width: 100%;" v-model="value" placeholder="请选择风格">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div> -->
                        <div v-for="item in list" :key="item.id+'1'">
                            <div class="list" v-if="item.value?.slice(0,2)=='#1'">
                                <div class="list-tit">
                                    {{item.label}}
                                </div>
                                <div class="list-content">
                                    <el-input size="small" :placeholder="'请输入'+item.label" v-model="item.content" @input="changeInput($event,item)">
                                    </el-input>
                                </div>
                            </div>
                            <div class="list" v-if="item.value?.slice(0,2)=='#1' && item.type=='image'">
                                <div class="list-tit">
                                    {{item.label}}
                                </div>
                                <div class="list-content">
                                    <upload-img color="gray" width="100px" height="100px" :id="item.id" label="点击上传图片" :imageUrl="item.url" @uploadSuccess="uploadSuccess"></upload-img>
                                </div>
                            </div>
                        </div>

                        <div class="title mt30">
                            联系信息
                        </div>
                        <div v-for="item in list" :key="item.id+'2'">
                            <div class="list" v-if="item.value?.slice(0,2)=='#2' && item.type=='text'">
                                <div class="list-tit">
                                    {{item.label}}
                                </div>
                                <div class="list-content">
                                    <el-input size="small" :placeholder="'请输入'+item.label" v-model="item.content" @input="changeInput($event,item)">
                                    </el-input>
                                </div>
                            </div>
                            <div class="list" v-if="item.value?.slice(0,2)=='#2' && item.type=='image'">
                                <div class="list-tit">
                                    {{item.label}}
                                </div>
                                <div class="list-content">
                                    <upload-img color="gray" width="100px" height="100px" :id="item.id" label="点击上传图片" :imageUrl="item.url" @uploadSuccess="uploadSuccess"></upload-img>
                                </div>
                            </div>
                        </div>
                        <div class="title mt30">
                            公司信息
                        </div>
                        <div v-for="item in list" :key="item.id+'3'">
                            <div class="list" v-if="item.value?.slice(0,2)=='#3' && item.type=='text'">
                                <div class="list-tit">
                                    {{item.label}}
                                </div>
                                <div class="list-content">
                                    <el-input size="small" :placeholder="'请输入'+item.label" v-model="item.content" @input="changeInput($event,item)">
                                    </el-input>
                                </div>
                            </div>
                            <div class="list" v-if="item.value?.slice(0,2)=='#3' && item.type=='image'">
                                <div class="list-tit">
                                    {{item.label}}
                                </div>
                                <div class="list-content">
                                    <upload-img color="gray" width="100px" height="100px" :id="item.id+''" label="点击上传图片" :url="item.url" @uploadSuccess="uploadSuccess"></upload-img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 第二步 -->
                    <div class="from-l" v-if="step==2">
                        <div class="list">
                            <div class="list-tit" style="width: 90px;">
                                配音方式
                            </div>
                            <div class="list-content" style="line-height: 32px;">
                                <el-radio v-model="audioType" :label="1">上传录音</el-radio>
                                <el-radio v-model="audioType" :label="2">AI合成</el-radio>
                            </div>
                        </div>
                        <!-- 上传声音 -->
                        <div class="list" v-if="audioType==1">
                            <div class="list-tit" style="width: 130px;">

                            </div>
                            <div class="list-content">
                                <upload-audio @uploadSuccess="uploadSuccessAudio" :audioUrl="audioUrl"></upload-audio>
                            </div>
                        </div>
                        <!-- 合成声音 -->
                        <div v-if="audioType==2">
                            <div class="list-tit">
                                当前音色
                            </div>
                            <card-audio :default_tts="default_tts" :sample_rate="sample_rate" :labels="labels" :tts_list="tts_list" @postEmit="postEmit"></card-audio>
                        </div>
                        <div class="input-box" v-if="audioType==2">
                            <el-input type="textarea" placeholder="请输入介绍内容，长度不超过500字符" @input="handleInput" rows="6" v-model="textarea" maxlength="500" show-word-limit>
                            </el-input>
                            <el-button class="btnBgColor_blue w80" size="small" style="margin-top:12px;" :loading="btnLoading" @click="audition" v-if="playType==1">试听</el-button>
                            <el-button class="btnBgColor_blue" size="small" style="margin-top:12px;" :loading="btnLoading" @click="play" v-if="playType==2">重新播放</el-button>
                            <el-button class="btnBgColor_blue" size="small" style="margin-top:12px;" :loading="btnLoading" @click="stop" v-if="playType==3">停止播放</el-button>
                            <audio v-if="audioPlay" :src="audioAi" @ended="overAudio" autoplay="autoplay" hidden></audio>
                        </div>
                    </div>
                    <div class="from-r">
                        <div class="title mt20 mb14">
                            预览
                        </div>
                        <!-- 名片效果展示 -->
                        <card :list="list"></card>
                        <!-- 操作 -->
                        <div class="btn-box">
                            <el-button size="mini" @click="dialogVisible=false">取消</el-button>
                            <el-button class="btnBgColor_blue" size="mini" v-if="step==1" @click="step=2">下一步</el-button>
                            <el-button size="mini" v-if="step==2" @click="step=1">上一步</el-button>
                            <el-button class="btnBgColor_blue" size="mini" v-if="step==2" :loading="composeLoading" @click="compose">一键合成</el-button>
                            <div class="btn" @click="moreEdit">更多编辑</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script> 
import UploadImg from '@/components/upload/uploadDraw.vue'  //上传图片
import UploadAvatar from '@/components/upload/uploadAvatar.vue'  //上传头像  新上传接口判断是否有人来
import UploadAudio from '@/components/upload/uploadAudio.vue'  //上传音频
import CardAudio from '../cardAudio/index.vue'  //选择声音
import Card from './card.vue'
export default {
    components: { UploadImg, Card, UploadAvatar, UploadAudio, CardAudio },
    data() {
        return {
            dialogVisible: false,
            btnLoading: false,
            composeLoading: false,  //合成loading
            step: 1,
            audioType: 1,
            gender: 1,
            options: [],
            value: '',
            list: [],
            avatar: '',
            cardInfo: {},
            audioUrl: '',  //上传的声音

            default_tts: {}, //默认声音
            sample_rate: [],  //采样率
            labels: [],  //声音分类
            tts_list: [],  //声音列表
            textarea: '',

            audioAi: '',  //合成的声音
            audioPlay: false,  //是否播放

            saveText: '',  //合成视频后保存文案  文案变动按钮变化
        };
    },
    created() {

        this.getParams()
    },
    computed: {
        //1试听   2重新播放  3停止播放
        playType() {
            if (this.audioAi == '') {
                return 1
            } else {
                if (this.saveText == this.textarea) {
                    if (this.audioPlay) {
                        return 3
                    } else {
                        return 2
                    }
                } else if (this.saveText != this.textarea) {
                    return 1
                }
            }
        }
    },
    watch: {
        dialogVisible(val) {
            if (!val) {
                this.step = 1
            }
        },
    },
    methods: {
        // 更多编辑
        moreEdit() {
            // 处理数据
            let arr = []
            let newlist = JSON.parse(JSON.stringify(this.list))
            newlist.forEach(ele => {
                if (ele.type == 'text') {
                    if (ele.content != '') {
                        arr.push(ele)
                    }
                } else if (ele.type == 'image' || ele.type == 'avatar') {
                    if (ele.url != '') {
                        arr.push(ele)
                    }
                } else {
                    arr.push(ele)
                }
            })
            let str = {
                template_from: 2,
                aspect_ratio: 1,
                fps: 25,
                bit_rate: '3M',
                resolution: '1920*1080',
                config: [
                    {
                        id: new Date().getTime(),
                        data: {
                            ttsAudio: "",
                            content: '',
                            ...this.default_tts
                        },
                        scenes_conf: arr,
                        background: {
                            type: "",
                            url: "",
                            objectFit: "cover",
                            color: ""
                        }
                    }
                ],
            }
            let obj = {
                template_from: 2,
                from: 2,
                aspect_ratio: 1,
                config: JSON.stringify(str),
                model_list_id: this.cardInfo.id,
                bit_rate: '3M',
                resolution: '1920*1080'
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$digitalApi.creatCard(obj).then(res => {
                loading.close();
                if (res.code == 1000) {
                    this.dialogVisible = false
                    this.$router.push({
                        path: '/createvideo/index/' + res.result.id,
                        query: {
                            name: '新建名片'
                        },
                    })
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 合成
        compose() {
            let arr = []
            let newlist = JSON.parse(JSON.stringify(this.list))
            newlist.forEach(ele => {
                if (ele.type == 'text') {
                    if (ele.content != '') {
                        arr.push(ele)
                    }
                } else if (ele.type == 'image' || ele.type == 'avatar') {
                    if (ele.url != '') {
                        arr.push(ele)
                    }
                } else {
                    arr.push(ele)
                }
            })
            this.cardInfo.scenes_conf = arr
            this.cardInfo = {
                gender: this.gender,
                audioType: this.audioType,
                ...this.default_tts
            }

            let obj = {
                template_from: 2,
                config: JSON.stringify(this.cardInfo),
                tts: this.audioType == 2 ? this.default_tts.id : '',
            }
            this.composeLoading = true
            this.$digitalApi.creatCard(obj).then(res => {
                this.composeLoading = false
                if (res.code == 1000) {
                    this.$succMsg('创建成功')
                    this.dialogVisible = false
                    this.$router.push({
                        path: '/digital/combine_card',
                        query: {
                            id: res.result.id
                        }
                    })
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        open(item) {
            this.cardInfo = item
            this.dialogVisible = true
            if (!item.config_json.config) {
                return
            }
            let arr = item.config_json.config[0].scenes_conf
            arr.forEach(ele => {
                if (ele.value) {
                    ele.content = ''
                    if (ele.value.indexOf("#") != -1) {
                        ele.label = ele.value.slice(3, 10)
                        ele.url = ''
                    }
                    if (ele.start) {
                        ele.start = 0
                        ele.end = 0
                    }
                }
            })
            this.list = arr
        },
        // 试听
        audition() {
            if (this.textarea == '') {
                this.$errMsg('请输入试听内容')
                return
            }
            let obj = {
                content: this.textarea,
                speed_rate: this.default_tts.speed_rate,
                volume: this.default_tts.volume,
                pitch: this.default_tts.pitch,
                sample_rate: this.default_tts.sample_rate,
                tts_list_id: this.default_tts.id,
                tts_list_name: this.default_tts.name,
            }
            this.audioPlay = false
            this.btnLoading = true
            this.$digitalApi.postAudition(obj).then(res => {
                this.btnLoading = false
                this.audioAi = URL.createObjectURL(res)
                this.audioPlay = true
                this.saveText = this.textarea
            })
        },
        // 如果输入框有内容, 显示label 没有不显示
        changeInput(val, item) {
            this.list.forEach(ele => {
                if (ele.value == item.value.slice(3, 10)) {
                    if (val == '') {
                        ele.content = ''
                    } else {
                        ele.content = item.label
                    }
                }
            })
        },
        // 获取配置
        getParams() {
            let obj = {
                id: 128,
            }
            this.$digitalApi.getTtsParams(obj).then(res => {
                if (res.code == 1000) {
                    this.default_tts = res.result.default_tts
                    this.sample_rate = res.result.sample_rate
                    this.labels = res.result.labels
                    let arr = res.result.tts_list
                    arr.forEach(ele => {
                        ele.show = false
                    })
                    this.tts_list = arr
                }
            })
        },
        // 组件传来声音配置
        postEmit(val) {
            this.default_tts = val
        },
        // 上传音频
        uploadSuccessAudio(url) {
            this.audioUrl = url
        },
        // 上传图片
        uploadSuccess(short_url, url, id) {
            this.list.forEach(ele => {
                if (ele.id == id) {
                    ele.url = url
                }
            })
        },
        // 上传头像
        uploadSuccessAvatar(url) {
            this.avatar = url
            this.list.forEach(ele => {
                if (ele.type == 'avatar') {
                    ele.url = url
                }
            })
        },
        //    播放试听
        play() {
            this.audioPlay = true
        },
        // 暂停试听
        stop() {
            this.audioPlay = false
        },
        // 播放完自动暂停
        overAudio() {
            this.audioPlay = false
        },
        // 限制输入空格
        handleInput(value) {
            this.textarea = value.replace(/\s+/g, '');
        }
    },
};
</script>

<style scoped lang="scss">
.card-main {
    width: 100%;
    border-top: 1px solid #e7e7e7;
    padding: 0 30px;
    user-select: none;
    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 30px 0;
        .line {
            width: 125px;
            height: 1px;
            background: #e0e0e0;
            margin: 0 20px;
        }
        .step-item {
            display: flex;
            align-items: center;
            .number {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #cbcbcb;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                text-align: center;
                line-height: 16px;
            }
            .text {
                margin-left: 10px;
                .tit {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 20px;
                }
                .desc {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;
                    padding-top: 4px;
                }
            }
        }
        .step-item.active {
            .number {
                background: #2e4bf2;
                color: #ffffff;
                border: 1px solid #2e4bf2;
            }
            .text {
                .tit {
                    color: #2e4bf2;
                }
            }
        }
    }
    .from {
        display: flex;
        justify-content: space-between;
        .title {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 17px;
        }
        .from-l {
            .list {
                width: 310px;
                display: flex;
                margin-top: 15px;
                .list-tit {
                    width: 50px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    color: #333333;
                    line-height: 32px;
                    span {
                        color: #c41717;
                    }
                }
                .list-content {
                    width: 270px;
                }
            }
        }
        .from-r {
            width: 332px;
        }
        .input-box {
            width: 270px;
            margin-top: 12px;
        }
    }
    .btn-box {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .btn {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2e4bf2;
            text-decoration: underline;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
.mt20 {
    margin-top: 20px;
}
.mt30 {
    margin-top: 30px;
}
.mb14 {
    margin-bottom: 14px;
}

.w80 {
    width: 80px;
}
::v-deep .el-textarea__inner {
    font-size: 12px;
}
::v-deep .el-dialog__body {
    padding: 10px 0 30px;
}
</style>
