<template>
    <div class="card">
        <div class="card-inner">
            <div :class="item.type+'-inner'" :style="innerStyle(item.style)" v-for="item in list" :key="item.id">
                <div class="text" v-if="item.type=='text'" :style="textStyle(item.textStyle)">
                    {{item.content}}
                </div>
                <div class="image" v-if="item.type=='avatar'|| item.type=='background' ||item.type=='image' && item.url">
                    <img :src="item.url" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: Array
    },
    data() {
        return {

        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        innerStyle(item) {
            return {
                position: 'absolute',
                top: item.top + 'px',
                left: item.left + 'px',
                width: item.width + 'px',
                height: item.height + 'px',
                transform: 'rotateZ(' + item.rotation + 'deg)'
            }
        },
        textStyle(item) {
            return {
                color: item.color,
                fontFamily: item.fontFamily,
                fontSize: item.fontSize + 'px',
                fontStyle: item.fontStyle,
                fontWeight: item.fontWeight,
                lineHeight: item.lineHeight,
                letterSpacing: item.letterSpacing + 'px',
                textAlign: item.textAlign,
            }
        },
    },
};
</script>

<style scoped lang="scss">
.card {
    position: relative;
    width: 332px;
    height: 186px;
    border-radius: 3px;
    overflow: hidden;
    .card-inner {
        position: absolute;
        height: 1080px;
        width: 1920px;
        left: 50%;
        top: 50%;
        transform: scale(0.172917) translate(-50%, -50%);
        transform-origin: 0 0;
        .image {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
