<template>
    <div class="uploadAudio">
        <el-upload class="avatar-uploader" action="#" accept="audio/wav,audio/mp3,audio/aac,audio/m4a,audio/x-m4a,audio/mpeg" :show-file-list="false" :http-request="uploadSuccess">
            <el-button type="primary" class="btnBgColor_blue_empty w240" icon="el-icon-upload2" :loading="loading">{{audioUrl?'重新上传':'上传文件'}}</el-button>
        </el-upload>
        <div class="audio w240" v-if="audioUrl">
            <div class="play" @click="handlePauseOrPlay">
                <img src="@/assets/images/workben/play.png" v-show="paused" ref="pause" alt="">
                <img src="@/assets/images/workben/stop.png" v-show="!paused" ref="stop" alt="">
            </div>
            <div class="progress" ref="progress">
                <div class="currentProgress" ref="currentProgress">
                </div>
            </div>
            <div class="time">
                {{duration}}
            </div>
        </div>
        <audio @canplay="getDuration" controls @timeupdate="updateTime" @ended="overAudio" v-show="false" ref="audio" :src="audioUrl" />
        <!-- <audio id="audio" ref="audio" controls></audio> -->
    </div>
</template>

<script>
import Crunker from 'crunker'
export default {
    props: {
        audioUrl: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            duration: "00:00",
            loading: false,
            audio: "",
            paused: true,
            isMoveIn: false, //是否在移动中
            acceptList: ['audio/wav', 'audio/mp3', 'audio/aac', 'audio/m4a', 'audio/x-m4a', 'audio/mpeg'],
            // audioUrl: 'https://cdn.guiji.ai/vpp/vpp/2023/09/22/mp3/1705159728351879169.wav',

            list: []
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        //视频在可以播放时触发
        getDuration() {
            this.duration = this.timeFormat(this.$refs.audio.duration);
            this.audio = this.$refs.audio;
        },
        //暂停或播放
        handlePauseOrPlay() {
            this.audio.paused ? this.audio.play() : this.audio.pause();
            this.paused = !this.paused;
        },
        // 播放结束
        overAudio() {
            this.$refs.currentProgress.style.width = "0px";
            this.paused = true;
        },
        //进度条发生变化时触发
        updateTime() {
            if (!this.$refs.progress) return;
            //如果不是正在移动 和 没有暂停播放就执行
            if (!this.isMoveIn || !this.audio.paused) {
                // 设置当前时间
                let MoveX =
                    this.$refs.progress.clientWidth *
                    (this.audio.currentTime / this.audio.duration);
                //播放时更新距离
                this.$refs.currentProgress.style.width = MoveX + "px";
            }
        },
        //将单位为秒的的时间转换成mm:ss的形式
        timeFormat(number) {
            let minute = parseInt(number / 60);
            let second = parseInt(number % 60);
            minute = minute >= 10 ? minute : "0" + minute;
            second = second >= 10 ? second : "0" + second;
            return minute + ":" + second;
        },
        uploadSuccess(item) {

            var self = this
            // this.list = []
            // this.audioShear(item.file, 5, 10)
            // this.audioShear(item.file, 15, 20)
            // let crunker = new Crunker();
            // console.log(this.list)
            // setTimeout(() => {
            //     const resultBuffer = crunker.concatAudio([...this.list]);
            //     console.log(resultBuffer)
            //     var blob = self.bufferToWave(resultBuffer, 480000);
            //     let adu = self.$refs.audio;
            //     adu.src = URL.createObjectURL(blob);
            // }, 1000)
            // return


            // 限制图片格式
            const isType = this.acceptList.includes(item.file.type)
            // 限制图片大小
            const isLt50M = item.file.size / 1024 / 1024 <= 50;
            if (!isType) {
                self.$errMsg('上传视频只能是 MP3 , mav, acc 格式!');
            }
            if (!isLt50M) {
                self.$errMsg('上传视频大小不能超过 50MB!');
            }
            if (isType && isLt50M) {
                let newFile = new FormData()
                newFile.append('type', 5);
                newFile.append('file', item.file);
                self.loading = true
                self.$publicApi.uploadImg(newFile).then(res => {
                    self.loading = false
                    if (res.code == 1000) {
                        self.$emit('uploadSuccess', res.result.url)
                        self.duration = "00:00";
                        self.paused = true;
                        if (self.$refs.currentProgress) {
                            self.$refs.currentProgress.style.width = "0px";
                        }
                    } else {
                        self.$errMsg(res.message)
                    }
                })
            }
        },
        audioShear(file, start, end) {
            let that = this
            // var target = event.target;
            // var file = target.file;
            // var type = file.type;
            // 开始识别
            var newAudioBuffer;
            var reader = new FileReader();

            reader.onload = function (event) {
                console.log(event.target.result)
                var arrBuffer = event.target.result;

                var audioCtx = new AudioContext();

                audioCtx.decodeAudioData(arrBuffer, function (audioBuffer) {
                    console.log(audioBuffer)
                    var duration = audioBuffer.duration;
                    var channels = audioBuffer.numberOfChannels;
                    var rate = audioBuffer.sampleRate;
                    // 时间
                    var startOffset = rate * start;
                    var endOffset = rate * end;
                    var frameCount = endOffset - startOffset;
                    console.log(frameCount)


                    newAudioBuffer = new AudioContext().createBuffer(channels, endOffset - startOffset, rate);
                    var anotherArray = new Float32Array(frameCount);
                    var offset = 0;

                    for (var channel = 0; channel < channels; channel++) {
                        audioBuffer.copyFromChannel(anotherArray, channel, startOffset);
                        newAudioBuffer.copyToChannel(anotherArray, channel, offset);
                    }

                    /**
                    * 直接播放使用下面的代码
                    // 创建AudioBufferSourceNode对象
                    var source = audioCtx.createBufferSource();
                    // 设置AudioBufferSourceNode对象的buffer为复制的n秒AudioBuffer对象
                    source.buffer = newAudioBuffer;
                    // 这一句是必须的，表示结束，没有这一句没法播放，没有声音
                    // 这里直接结束，实际上可以对结束做一些特效处理
                    source.connect(audioCtx.destination);
                    // 资源开始播放
                    source.start();
                    */
                    that.list.push(newAudioBuffer)
                    // console.log(newAudioBuffer)
                    // var blob = that.bufferToWave(newAudioBuffer, frameCount);

                    // let adu = that.$refs.audio;

                    // adu.src = URL.createObjectURL(blob);
                    // console.log(URL.createObjectURL(blob))
                    // console.log(adu)
                    /**
                    * 转换成Base64使用下面的代码
                    var reader2 = new FileReader();
                    reader2.onload = function(evt){
                        audio.src = evt.target.result;
                    };
                    reader2.readAsDataURL(blob);
                    */
                    // 使用Blob地址
                    // this.audio.src = URL.createObjectURL(blob);
                    // this.$refs.audio.src = URL.createObjectURL(blob)
                    // console.log(this.audioUrl)
                });
            };
            reader.readAsArrayBuffer(file);


        },
        bufferToWave(abuffer, len) {
            console.log(abuffer)
            var numOfChan = abuffer.numberOfChannels,
                length = len * numOfChan * 2 + 44,
                buffer = new ArrayBuffer(length),
                view = new DataView(buffer),
                channels = [], i, sample,
                offset = 0,
                pos = 0;

            // write WAVE header
            setUint32(0x46464952);                         // "RIFF"
            setUint32(length - 8);                         // file length - 8
            setUint32(0x45564157);                         // "WAVE"

            setUint32(0x20746d66);                         // "fmt " chunk
            setUint32(16);                                 // length = 16
            setUint16(1);                                  // PCM (uncompressed)
            setUint16(numOfChan);
            setUint32(abuffer.sampleRate);
            setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
            setUint16(numOfChan * 2);                      // block-align
            setUint16(16);                                 // 16-bit (hardcoded in this demo)

            setUint32(0x61746164);                         // "data" - chunk
            setUint32(length - pos - 4);                   // chunk length

            // write interleaved data
            for (i = 0; i < abuffer.numberOfChannels; i++)
                channels.push(abuffer.getChannelData(i));

            while (pos < length) {
                for (i = 0; i < numOfChan; i++) {             // interleave channels
                    sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
                    sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
                    view.setInt16(pos, sample, true);          // write 16-bit sample
                    pos += 2;
                }
                offset++                                     // next source sample
            }

            // create Blob
            return new Blob([buffer], { type: "audio/wav" });

            function setUint16(data) {
                view.setUint16(pos, data, true);
                pos += 2;
            }

            function setUint32(data) {
                view.setUint32(pos, data, true);
                pos += 4;
            }
        }
    },
};
</script>

<style scoped lang="scss">
.uploadAudio {
    .audio {
        height: 40px;
        background: #f3f3f3;
        border-radius: 4px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        .play {
            width: 20px;
            height: 20px;
            margin: 0 6px 0 16px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .progress {
            width: 150px;
            height: 4px;
            border-radius: 2px;
            background: #e5e5e5;
            .currentProgress {
                position: relative;
                height: 100%;
                width: 0;
                background: #2e4bf2;
                border-radius: 2px;
            }
        }
        .time {
            font-size: 12px;
            color: #333333;
            margin-left: 6px;
        }
    }
}
.w240 {
    width: 240px;
}
</style>
