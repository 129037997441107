<!-- @Author: Yu_Bo -->
<template>
    <div class='empty_data'>
        <img :src="imgUrl" alt="">
        <span>{{ text }}</span>
        <el-button v-if="is_video" class="btnBgColor_blue mt20" size="small">去创作 ></el-button>
        <el-button v-if="is_audio" class="btnBgColor_blue mt20" size="small" @click="audioCreate">去创作 ></el-button>
        <el-button v-if="is_draw" class="btnBgColor_blue mt20" size="small" @click="toPage('is_draw')">去创作 ></el-button>
        <el-button v-if="is_cartoon" class="btnBgColor_blue mt20" size="small" @click="toPage('is_cartoon')">去创作
            ></el-button>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        img: {
            type: String,
            defaul: ''
        },
        text: {
            type: String,
            defaul: '暂无数据'
        },
        is_video: {
            type: Boolean,
            defaul: false
        },
        is_audio: {
            type: Boolean,
            defaul: false
        },
        is_draw: {
            type: Boolean,
            defaul: false
        },
        is_cartoon: {
            type: Boolean,
            defaul: false
        },
    },
    data() {
        return {

        }
    },
    computed: {
        imgUrl() {
            if (this.img) {
                return require('@/assets/images/no_html/' + this.img)
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        toPage(val) {
            let path = ''
            if (val == 'is_draw') { // 绘画
                path = '/workben/painting'
            }
            if (val == 'is_cartoon') { // 漫画
                path = '/workben/comics'
            }
            this.$router.push({
                path: path,
            })
        },
        // 创建音频
        audioCreate() {
            var that = this
            var params = {
                order_source: 1
            }
            that.$audioApi.quickCreate(params).then(res => {
                if (res.code == 1000) {
                    that.$router.push({
                        path: '/createaudio/index/' + res.result.id,
                        query: {
                            name: res.result.name
                        }
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.empty_data {
    width: 100%;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        display: block;
        width: 260px;
        height: 200px;
    }

    span {
        padding-top: 10px;
        line-height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }

    .mt20 {
        margin-top: 20px;
    }
}
</style>
