<template>
    <div class="model-market">
        <div class="model-search">
            <div class="model-search-c">
                <div class="tit">宽高比：</div>
                <div class="select-box">
                    <el-select size="small" v-model="proportion" placeholder="全部" clearable @change="search">
                        <el-option v-for="item in proportionList" :key="item.label" :label="item.value" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="tit">风格：</div>
                <div class="select-box">
                    <el-select size="small" v-model="style" placeholder="全部" clearable @change="search">
                        <el-option v-for="item in styleList" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
                <div class="tit">艺术家：</div>
                <div class="select-box">
                    <el-select size="small" v-model="artist" placeholder="全部" clearable @change="search">
                        <el-option v-for="item in artistList" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="model-search-c">
                <div class="search-box">
                    <el-input size="small" v-model="name" @keyup.enter.native="search" placeholder="搜索图片名称" suffix-icon="el-icon-search"></el-input>
                </div>
            </div>
        </div>
        <div class="list" v-loading="loading" element-loading-background="rgba(195,216,247,0.9)">
            <empty-data v-if="list.length==0" img='no_img_b.png' text='暂无数据'></empty-data>
            <div class="list-item" v-for="item,index in list" :key="index">
                <div class="list-img">
                    <div class="tips" v-if="item.is_free=='1'">限时免费</div>
                    <img :src="item.production_file" alt="">
                </div>
                <div class="item-bottom">
                    <div class="item-bottom-l">
                        <div class="item-name">{{item.name}}</div>
                    </div>
                    <div class="item-bottom-r">
                        <div class="open-make" @click="toPath(item.id)">
                            引用
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <paging-page :total='total' :pageSizes="[12,24,36,48]" align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { EmptyData, PagingPage },
    data() {
        return {
            page: 1,
            limit: 12,
            total: 0,
            loading: false,
            proportionList: [],  //宽高比
            styleList: [],  //风格
            artistList: [],  //艺术家
            list: [],
            proportion: '',
            style: '',
            artist: '',
            name: '',
        };
    },
    created() {
        this.getDrawParams()
        this.getList()
    },
    methods: {
        getDrawParams() {
            this.loading = true
            this.$digitalApi.drawParams().then(res => {
                if (res.code == 1000) {
                    this.proportionList = res.result.proportion
                    this.styleList = res.result.style
                    this.artistList = res.result.artist
                }
            })
        },
        getList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                proportion: this.proportion,
                artist: this.artist,
                style: this.style,
                name: this.name,
            }
            this.loading = true
            this.$digitalApi.drawList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.total = res.result.total
                    this.list = res.result.data
                }
            })
        },
        search() {
            this.page = 1;
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList()
        },
        toPath(id) {
            this.$router.push(
                {
                    path: '/workben/painting',
                    query: {
                        id: id
                    },
                }
            )
        },
    },
};
</script>

<style scoped lang="scss">
.model-search {
    width: 100%;
    height: 62px;
    background: rgba($color: #ffffff, $alpha: 0.4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .model-search-c {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tit {
            font-size: 12px;
            color: #333333;
            line-height: 17px;
            padding-right: 10px;
        }
        .select-box {
            width: 112px;
            margin-right: 20px;
        }
        .search-box {
            width: 178px;
        }
    }
}
.list {
    display: flex;
    flex-wrap: wrap;
    min-height: 450px;
    margin-bottom: 20px;
    .list-item {
        margin-right: 20px;
        margin-top: 20px;
        width: 278px;
        height: 203px;
        border-radius: 11px;
        overflow: hidden;
        .list-img {
            width: 278px;
            height: 160px;
            background: #b4aca2;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
            .tips {
                position: absolute;
                top: 0;
                right: 0;
                width: 64px;
                height: 19px;
                background: linear-gradient(90deg, #ffd279 0%, #ffc04d 100%);
                border-radius: 0px 10px 0px 6px;
                color: #774501;
                font-size: 12px;
                text-align: center;
                line-height: 19px;
            }
        }
        .item-bottom {
            width: 278px;
            height: 43px;
            background: rgba(255, 255, 255, 0.9);
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item-bottom-l {
                .item-name {
                    font-size: 14px;
                    font-weight: 400;
                    color: $bColor3;
                    line-height: 20px;
                }
            }
            .item-bottom-r {
                .open-make {
                    width: 49px;
                    height: 21px;
                    background: $blueColor1;
                    border-radius: 2px;
                    color: #ffffff;
                    line-height: 21px;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
