<!-- @Author: Yu_Bo -->
<template>
    <div class='digital_market_main'>
        <el-carousel height="140px">
            <el-carousel-item v-for="item,index in bannerList" :key="index">
                <div class="banner">
                    <img :src="item.img" alt="">
                    <div class="banner-text">
                        <img class="banner-tit" :src="item.text" alt="">
                        <div class="banner-desc">{{item.desc}}</div>
                    </div>
                    <div class="banner-btn" @click="toPath(item.path)">{{item.btn}} ></div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="tabs">
            <div class="tabs-item" v-for="item in tabs" :key="item.id" @click="tabsChange(item)">
                <div class="tabs-item-c" :class="{active:item.id==active}">
                    <div class="vip" v-if="item.name=='数字名片'">VIP会员免费用</div>
                    <img class="bgimg" :src="item.bgimg" alt="">
                    <p class="tabs-title">{{item.name}}</p>
                    <p class="tabs-desc" v-if="item.open=='2'">敬请期待</p>
                </div>
            </div>
        </div>
        <model-market v-if="active==1"></model-market>
        <business-card v-if="active==2"></business-card>
        <formwork-market v-if="active==3"></formwork-market>
        <sound-market v-if="active==4"></sound-market>
        <draw-market v-if="active==5"></draw-market>
    </div>
</template> 

<script>
import ModelMarket from './components/model_market/index.vue'    //模特市场
import BusinessCard from './components/business_card/index.vue'  //数字名片
import FormworkMarket from './components/formwork_market/index.vue'    //模板市场
import SoundMarket from './components/sound_market/index.vue'    //声音市场
import DrawMarket from './components/draw_market/index.vue'    //绘画市场
export default {
    components: {
        ModelMarket,
        BusinessCard,
        FormworkMarket,
        SoundMarket,
        DrawMarket,
    },
    props: {},
    data() {
        return {
            tabs: [
                {
                    name: "模特市场",
                    id: 1,
                    open: "1",
                    bgimg: require('@/assets/images/digital/tabs1.png'),
                },
                {
                    name: "数字名片",
                    id: 2,
                    open: "1",
                    bgimg: require('@/assets/images/digital/tabs2.png'),
                },
                {
                    name: "模板市场",
                    id: 3,
                    open: "1",
                    bgimg: require('@/assets/images/digital/tabs3.png'),
                },
                {
                    name: "声音市场",
                    id: 4,
                    open: "1",
                    bgimg: require('@/assets/images/digital/tabs4.png'),
                },
                {
                    name: "绘画市场",
                    id: 5,
                    open: "1",
                    bgimg: require('@/assets/images/digital/tabs5.png'),
                },
                {
                    name: "内容市场",
                    id: 6,
                    open: "2",
                    bgimg: require('@/assets/images/digital/tabs6.png'),
                },
            ],
            active: 1,
            bannerList: [
                {
                    img: require('@/assets/images/digital/banner1.png'),
                    text: require('@/assets/images/digital/text1.png'),
                    title: '云川APP',
                    desc: '—— 打开云川app开始探索',
                    btn: '立即探索',
                    path: '',
                },
                {
                    img: require('@/assets/images/digital/banner2.png'),
                    text: require('@/assets/images/digital/text2.png'),
                    title: '数字孪生',
                    desc: '—— 定制您的专属数字人',
                    btn: '立即定制',
                    path: '/workben/digital',
                },
                {
                    img: require('@/assets/images/digital/banner3.png'),
                    text: require('@/assets/images/digital/text3.png'),
                    title: 'AI漫画',
                    desc: '—— 开启您的异次元之旅',
                    btn: '立即创作',
                    path: '/workben/comics',
                },
                {
                    img: require('@/assets/images/digital/banner4.png'),
                    text: require('@/assets/images/digital/text4.png'),
                    title: '数字名片',
                    desc: '—— 开启商务社交新姿态',
                    btn: '立即查看',
                    path: '',
                },
                {
                    img: require('@/assets/images/digital/banner5.png'),
                    text: require('@/assets/images/digital/text4.png'),
                    title: 'AI绘画',
                    desc: '—— 助你实现天马行空的艺术创作',
                    btn: '立即创作',
                    path: '/workben/painting',
                },
                {
                    img: require('@/assets/images/digital/banner6.png'),
                    text: require('@/assets/images/digital/text6.png'),
                    title: 'KOL群英征集令',
                    desc: '—— 500W+粉丝KOL 免费拥有云川原宇宙“虚拟数字人”',
                    btn: '立即加入',
                    path: '/callUp',
                },
            ],
        }
    },
    created() {
      if(this.$route.query.type){
        this.active=this.$route.query.type*1
      }
    },
    methods: {
        // 跳转
        toPath(url) {
            this.$router.push(url)
        },
        tabsChange(val) {
            if (val.id == this.active || val.open == '2') {
                return
            } else {
                this.active = val.id
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.digital_market_main {
    min-width: 1450px;
    .banner {
        width: 100%;
        height: 140px;
        position: relative;
        color: #ffffff;
        img {
            width: 100%;
            height: 100%;
        }
        .banner-text {
            position: absolute;
            top: 42px;
            left: 365px;
            .banner-tit {
                width: auto;
                height: 27px;
            }
            .banner-desc {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 22px;
                letter-spacing: 2px;
                padding-top: 18px;
            }
        }
        .banner-btn {
            cursor: pointer;
            width: 160px;
            height: 50px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 25px;
            border: 1px solid #e4e4e4;
            line-height: 50px;
            font-size: 16px;
            text-align: center;
            position: absolute;
            top: 45px;
            right: 365px;
        }
    }
    .tabs {
        display: flex;
        align-items: center;
        height: 77px;
        margin: 17px 0;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        .tabs-item {
            width: 250px;
            .tabs-item-c {
                width: 220px;
                height: 70px;
                border-radius: 12px;
                border: 1px solid #ffffff;
                position: relative;
                cursor: pointer;
                .vip {
                    position: absolute;
                    top: -8px;
                    right: 38px;
                    width: 92px;
                    height: 22px;
                    line-height: 22px;
                    text-align: center;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #844c1a;
                    background: linear-gradient(
                        180deg,
                        #ffd193 0%,
                        #ffc085 100%
                    );
                    z-index: 10;
                    border-radius: 12px 12px 12px 0;
                }
                .bgimg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                }
                .tabs-title {
                    position: relative;
                    font-size: 18px;
                    color: #333333;
                    line-height: 25px;
                    padding: 14px 0 0 20px;
                    z-index: 2;
                }
                .tabs-desc {
                    font-size: 14px;
                    color: #999999;
                    line-height: 20px;
                    padding: 2px 0 0 20px;
                    z-index: 2;
                    position: relative;
                }
            }
            .tabs-item-c.active {
                width: 230px;
                height: 77px;
                box-shadow: 0px 8px 20px 0px rgba(150, 169, 255, 0.8);
                border: 2px solid #2e4bf2;
            }
        }
    }
}
</style>