<template>
    <div>
        <div class="model-search">
            <div class="model-search-l">
                <div class="model-search-c">
                    <div class="search-class" :class="{active:classifyActive==item.value}" v-for="item in classify" :key="item.value" @click="changeClassify(item.value)">
                        {{item.label}}
                    </div>
                </div>
            </div>
            <div class="model-search-r">
                <div class="select-box">
                    <el-select size="small" v-model="aspect_ratio" placeholder="全部" clearable @change="search">
                        <el-option v-for="item in aspectRatio" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-box">
                    <el-input size="small" v-model="name" placeholder="搜索名片关键字" @keyup.enter.native="search"></el-input>
                </div>
            </div>
        </div>
        <div class="list" v-loading="loading" element-loading-background="rgba(195,216,247,0.9)">
            <empty-data v-if="list.length == 0" img='no_img_b.png' text='暂无数据'></empty-data>
            <div class="list-item" v-for="item,index in list" :key="index">
                <div class="list-img">
                    <!-- <div class="tips" v-if="item.type=='1'">付费</div> -->
                    <div class="tips vip" v-if="item.is_vip == '1'">
                        <img src="@/assets/images/digital/vip.png" alt=""> VIP
                    </div>
                    <div class="video" v-if="item.id==current">
                        <video controls="controls" autoplay="autoplay" :src="item.video_url" @click="current=''"></video>
                    </div>
                    <div class="cover" v-show="item.id!==current">
                        <img class="img" :src="item.thumbnail" alt="">
                        <img class="play" src="@/assets/images/digital/play.png" @click="changeVideo(item)" alt="">
                    </div>
                </div>
                <div class="item-bottom">

                    <div class="item-bottom-t">
                        <div class="item-name">{{item.name}}</div>
                        <div class="item-price">
                            <!-- <span v-if="item.type=='1'">199/次</span> -->
                            <!-- <div class="btn" v-if="item.type=='1'">
                                购买
                            </div> -->
                            <div class="open-vip" v-if="item.is_vip == '1' && !userInfo.is_vip" @click="memberBtn">
                                开通会员
                            </div>
                            <div class="btn" v-else @click="open(item)">
                                使用
                            </div>
                        </div>
                    </div>
                    <div class="item-info">
                        <div class="item-hot"><img src="@/assets/images/digital/hot.png" alt="">38055</div>
                    </div>
                </div>
            </div>
        </div>
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
        <open-member ref='dialogMember'></open-member>
        <creat-card ref="creatcard"></creat-card>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import OpenMember from '@/components/invest_money/open_member.vue' // 弹框==开通会员
import CreatCard from '../creat_card/index.vue' // 创建名片

import { mapState } from "vuex"
export default {
    components: { EmptyData, PagingPage, OpenMember, CreatCard },
    data() {
        return {
            loading: false,
            page: 1,
            limit: 12,
            total: 0,
            classify: [],
            aspectRatio: [],
            classifyActive: "",
            aspect_ratio: "",
            name: '',
            list: [],
            current: '',
        };
    },
    created() {
        this.getCardParams()
        this.getList()
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
        }),
    },
    methods: {
        open(item) {
            this.$refs.creatcard.open(item)
        },
        getCardParams() {
            this.$digitalApi.cardParams().then(res => {
                if (res.code == 1000) {
                    this.classify = res.result.cat_list
                    this.classify.unshift(
                        {
                            value: "",
                            label: "全部",
                        },
                        {
                            value: "is_free",
                            label: "免费",
                        },
                    )
                    this.aspectRatio = res.result.aspect_ratio
                }
            })
        },
        getList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                is_free: this.classifyActive == 'is_free' ? '1' : '',
                category_id: this.classifyActive == 'is_free' ? '' : this.classifyActive,
                aspect_ratio: this.aspect_ratio,
                name: this.name,
            }
            this.loading = true
            this.$digitalApi.cardList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.total = res.result.total
                    this.list = res.result.data
                }
            })
        },
        search() {
            this.page = 1
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList()
        },
        // 购买会员
        memberBtn() {
            this.$refs.dialogMember.openDialogBtn()
        },
        changeClassify(val) {
            if (this.classifyActive == val) {
                return
            } else {
                this.page = 1;
                this.limit = 12;
                this.total = 0;
                this.aspect_ratio = '';
                this.name = '';
                this.classifyActive = val
                this.getList()
            }
        },
        // 切换播放视频
        changeVideo(val) {
            this.current = val.id
        },
    },
};
</script>

<style scoped lang="scss">
.model-search {
    width: 100%;
    background: rgba($color: #ffffff, $alpha: 0.4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    .model-search-l {
        width: calc(100% - 230px);
        .model-search-c {
            display: flex;
            flex-wrap: wrap;
            .search-class {
                font-size: 14px;
                margin-right: 20px;
                cursor: pointer;
                color: $blueColor1;
                width: 80px;
                line-height: 26px;
                height: 28px;
                background: rgba(46, 75, 242, 0.06);
                border-radius: 2px;
                border: 1px solid rgba(46, 75, 242, 0.06);
                text-align: center;
            }
            .search-class.active {
                border: 1px solid $blueColor1;
            }
        }
    }
    .model-search-r {
        display: flex;
        justify-content: flex-end;
        width: 230px;
        .tit {
            font-size: 12px;
            color: #333333;
            line-height: 17px;
            padding-right: 10px;
        }
        .select-box {
            width: 102px;
            margin-right: 20px;
        }
        .search-box {
            width: 178px;
        }
    }
}
.list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .list-item {
        margin-right: 30px;
        margin-top: 20px;
        width: 386px;
        height: 280px;
        border-radius: 10px;
        overflow: hidden;
        .list-img {
            width: 386px;
            height: 216px;
            position: relative;
            .cover {
                width: 100%;
                height: 100%;
                position: relative;
                .img {
                    width: 100%;
                    height: 100%;
                }
                .play {
                    width: 46px;
                    height: 46px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
            .video {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 10;
                display: flex;
                justify-content: center;
                video {
                    width: auto;
                    height: 100%;
                    margin: 0 auto;
                }
            }
            .tips {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 10px;
                height: 21px;
                background: linear-gradient(90deg, #ffd279 0%, #ffc04d 100%);
                border-radius: 0px 12px 0px 7px;
                color: #774501;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tips.vip {
                background: linear-gradient(90deg, #79a6ff 0%, #614dff 100%);
                color: #ffffff;
                line-height: 21px;
                font-size: 12px;

                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 2px;
                }
            }
        }

        .item-bottom {
            width: 386px;
            height: 64px;
            background: rgba(255, 255, 255, 0.9);
            padding: 10px;

            .item-bottom-t {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .item-name {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: $bColor3;
                    line-height: 16px;
                }
                .item-price {
                    display: flex;
                    align-items: center;
                    span {
                        color: $blueColor1;
                        font-size: 12px;
                        padding-right: 10px;
                        font-weight: 500;
                    }

                    .btn {
                        width: 58px;
                        height: 21px;
                        background: $blueColor1;
                        border-radius: 2px;
                        color: #ffffff;
                        line-height: 21px;
                        text-align: center;
                        font-size: 12px;
                        cursor: pointer;
                    }
                    .open-vip {
                        width: 68px;
                        height: 25px;
                        background: linear-gradient(
                            180deg,
                            #ffde9f 0%,
                            #ffd382 100%
                        );
                        border-radius: 2px;
                        color: #774501;
                        line-height: 25px;
                        text-align: center;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
            }

            .item-info {
                display: flex;
                align-items: center;
                line-height: 20px;
                padding-top: 2px;

                .item-hot {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: #ff5f5f;
                    img {
                        width: 15px;
                        height: 15px;
                        margin-right: 2px;
                    }
                }
            }
        }
    }
}
</style>
