<!-- @Author: Yu_Bo -->
<template>
	<div class='paging_page' :style="[style]">
    <div class="num">共<span>{{allPage}}</span>页/<span>{{total}}</span>条数据</div>
    <el-pagination
      background
      :current-page="currentPage"
      :page-sizes="pageSizes"
      layout="prev, pager, next, jumper, sizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      total: {
          type: Number,
          default: 0,
      },
      align:{
        type: String,
        default: 'right',
      },
      pageSizes:{
        type: Array,
        default: () =>[10, 20, 30, 40],
      }
    },
		data() {
			return{
        sizes:10,
        currentPage:1,
			}
		},
		computed: {
      style(){
        if(this.align=='right'){
          return {
            'justify-content': 'flex-end'
          }
        }else if(this.align=='left'){
          return {
            'justify-content': 'flex-start'
          }
        }else if(this.align=='center'){
          return {
            'justify-content': 'center'
          }
        }
      },
      allPage(){
        return Math.ceil(this.total/this.sizes)
      },
    },
		watch: {},
		created() {},
		mounted() {
      var that = this
      let pageText = document.getElementsByClassName('el-pagination__jump')[0]
      if (pageText) {
        pageText.childNodes[0].nodeValue = '跳至'
      }
      that.$nextTick(()=>{
        that.sizes=that.pageSizes[0]
      })
    },
		methods: {
      // 选择每页都多少条
      handleSizeChange(val){
        this.currentPage=1
        this.sizes=val
        this.$emit('sizeChange',val)
      },
      // 切换页数
      handleCurrentChange(val){
        this.currentPage=val
        this.$emit('currentChange',val)
      }
    },
	}
</script>

<style lang='scss' scoped>
	.paging_page{
    width: 100%;
    padding-right: 5px;
    display: flex;
    .num{
      padding-right: 13px;
      line-height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      span{
        color: #2E4BF2;
      }
    }
	}
  ::v-deep .el-pagination{
    padding: 0;
    .btn-prev{
      width: 32px;
      height: 32px;
      line-height: 30px;
      background: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #D6D6DD;
      .el-icon{
        font-size: 14px;
      }
    }
    .btn-next{
      width: 32px;
      height: 32px;
      line-height: 30px;
      background: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #D6D6DD;
      .el-icon{
        font-size: 14px;
      }
    }
    .el-pager{
      li{
        min-width: 32px;
        height: 32px;
        line-height: 30px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #D6D6DD;
      }
      li:not(.disabled).active{
        color: #2E4BF2;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #2E4BF2;
      }
    }
    .el-pagination__jump{
      margin-left: 13px;
      margin-right: 20px;
      line-height: 30px;
      .el-pagination__editor{
        height: 32px;
        .el-input__inner{
          height: 32px;
        }
      }
    }
    .el-pagination__sizes{
      margin: 0;
      padding: 0;
      height: 32px;
      .el-input{
        margin: 0;
      }
      .el-input__inner{
        height: 32px;
      }
    }
  }
</style>
