<template>
    <div class="model-market">
        <div class="model-search">
            <div class="model-search-l">
                <div class="search-nav" :class="{active:current==0}" @click="changeNav(0)">
                    全部
                </div>
                <div class="search-nav" :class="{active:current==item.id}" v-for="item in classify" :key="item.id" @click="changeNav(item.id)">
                    {{item.name}}
                </div>
            </div>
            <div class="model-search-r">
                <div class="search-box">
                    <el-input size="small" v-model="name" @keyup.enter.native="search" placeholder="输入关键词检索"></el-input>
                </div>
            </div>
        </div>
        <div class="list" v-loading="loading" element-loading-background="rgba(195,216,247,0.9)">
            <empty-data v-if="list.length==0" img='no_img_b.png' text='暂无数据'></empty-data>
            <div class="list-item" v-for="item,index in list" :key="index">
                <div class="list-img">
                    <div class="tips" v-if="item.is_free=='1'">限时免费</div>
                    <div class="collection" v-if="item.children.length">合辑</div>
                    <div class="img-c" @mouseenter="enter(item.id)" @mouseleave="leave">
                        <img class="avatar" :src="item.avatar" alt="">
                        <div class="mask" v-if="item.id==play">
                            <div class="gif"></div>
                            <audio :src="item.audio_url" loop='true' autoplay="autoplay" ref="MusicPlay" hidden></audio>
                        </div>
                    </div>
                </div>
                <div class="item-bottom">
                    <div class="item-info">
                        <div class="item-name">{{item.name}}</div>
                        <div class="item-label">{{item.scenes}}</div>
                    </div>
                    <div class="item-bottom-b">
                        <div class="item-hot"><img src="@/assets/images/digital/hot.png" alt=""> {{item.hits}}</div>
                        <div class="open-make" @click="tomake(item)">
                            去制作<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <paging-page :total='total' align='right' :pageSizes="[16,32,48,64]" @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { EmptyData, PagingPage },
    props: {

    },
    data() {
        return {
            page: 1,
            limit: 16,
            total: 0,
            loading: false,
            name: '',
            classify: [],
            current: 0,
            list: [],
            play: 'none',
        };
    },
    created() {
        this.getTtsParams()
        this.getList()
    },
    methods: {
        enter(val) {
            this.play = val
        },
        leave() {
            this.play = 'none'
        },
        getTtsParams() {
            this.loading = true
            this.$digitalApi.ttsParams().then(res => {
                if (res.code == 1000) {
                    this.classify = res.result
                }
            })
        },
        changeNav(val) {
            if (this.current == val) {
                return
            } else {
                this.page = 1;
                this.limit = 16;
                this.name = '';
                this.total = 0;
                this.current = val
                this.getList()
            }
        },
        getList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                category_id: this.current,
                name: this.name,
            }
            this.loading = true
            this.$digitalApi.ttsList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.total = res.result.total
                    this.list = res.result.data
                }
            })
        },
        search() {
            this.page = 1;
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList()
        },
        // 去制作
        tomake(item){
            var that = this
            var params = {
                tts_list_id: item.id,
                order_source: 1
            }
            that.$audioApi.quickCreate(params).then(res => {
                if (res.code == 1000) {
                    that.$router.push({
                        path: '/createaudio/index/' + res.result.id,
                        query: {
                            name: res.result.name
                        }
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
    },
};
</script>

<style scoped lang="scss">
.model-search {
    width: 100%;
    background: rgba($color: #ffffff, $alpha: 0.4);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 45px;
    .model-search-l {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 230px);
        .search-nav {
            width: 60px;
            color: $bColor3;
            line-height: 32px;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
        }
        .search-nav.active {
            color: $blueColor1;
        }
    }
    .model-search-r {
        display: flex;
        justify-content: flex-end;
        width: 230px;
        .tit {
            font-size: 12px;
            color: #333333;
            line-height: 17px;
            padding-right: 10px;
        }
        .select-box {
            width: 102px;
            margin-right: 20px;
        }
        .search-box {
            width: 178px;
        }
    }
}
.list {
    display: flex;
    flex-wrap: wrap;
    min-height: 450px;
    margin-bottom: 20px;
    .list-item {
        margin-right: 20px;
        margin-top: 20px;
        width: 204px;
        height: 353px;
        border-radius: 8px;
        overflow: hidden;
        .list-img {
            position: relative;
            width: 204px;
            height: 288px;
            background: linear-gradient(
                180deg,
                #ffffff 0%,
                #f0f5ff 38%,
                #e1ecff 100%
            );
            display: flex;
            align-items: center;
            justify-content: center;
            .img-c {
                width: 132px;
                height: 132px;
                border-radius: 50%;
                position: relative;
                img {
                    width: 132px;
                    height: 132px;
                    border-radius: 50%;
                }
                .mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(46, 75, 242, 0.3);
                    border-radius: 66px;
                }
                .gif {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 65px;
                    height: 65px;
                    background-image: url(~@/assets/images/digital/play.gif);
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    opacity: 0.9;
                }
            }
            .tips {
                position: absolute;
                top: 0;
                right: 0;
                width: 60px;
                height: 18px;
                background: linear-gradient(90deg, #ffd279 0%, #ffc04d 100%);
                border-radius: 0px 10px 0px 6px;
                color: #774501;
                font-size: 12px;
                text-align: center;
                line-height: 18px;
            }
            .collection {
                position: absolute;
                top: 0;
                left: 10px;
                width: 22px;
                height: 40px;
                background: linear-gradient(180deg, #fa815b 0%, #f22ecb 100%);
                text-align: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 15px;
                padding: 5px;
            }
        }

        .item-bottom {
            width: 204px;
            height: 65px;
            background: rgba(255, 255, 255, 0.9);
            padding: 12px;
            .item-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: 20px;
                .item-name {
                    width: 90px;
                    font-size: 14px;
                    color: $bColor3;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }
                .item-label {
                    text-align: right;
                    font-size: 12px;
                    color: $bColor9;
                    width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }
            }
            .item-bottom-b {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 4px;
                .item-hot {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: $bColor9;
                    line-height: 17px;
                    padding-top: 4px;
                    img {
                        width: 15px;
                        height: 15px;
                        margin-right: 2px;
                    }
                }
                .open-make {
                    width: 62px;
                    height: 21px;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 2px;
                    color: #ffffff;
                    line-height: 21px;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
